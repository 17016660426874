import { useState, useEffect } from "react"

function useBigScreen(maxWidth = 768) {
  const [ bigScreen, setBigScreen ] = useState(true)

  useEffect(() => {
    const resizeWatcher = () => {
      if (window.matchMedia(`(max-width: ${maxWidth}px)`).matches) {
        setBigScreen(false)
      } else {
        setBigScreen(true)
      }
    }
    window.addEventListener("resize", resizeWatcher)

    resizeWatcher()

    return () => {
      window.removeEventListener("resize", resizeWatcher)
    }
  }, [setBigScreen, maxWidth])

  return bigScreen
}

function useMedia(media: string) {
  const [match, setBigScreen] = useState(true)

  useEffect(() => {
    const resizeWatcher = () => {
      if (window.matchMedia(media).matches) {
        setBigScreen(false)
      } else {
        setBigScreen(true)
      }
    }
    window.addEventListener("resize", resizeWatcher)

    resizeWatcher()
    return () => {
      window.removeEventListener("resize", resizeWatcher)
    }
  }, [setBigScreen, media])

  return match
}

export { useMedia }

export default useBigScreen
